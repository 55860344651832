<template>
  <validation-observer
    ref="changePassword"
    v-slot="{ handleSubmit }"
    slim
  >
    <b-form
      ref="password-reset-form"
      class="px-2 py-1"
      @submit.prevent="handleSubmit(changePassword)"
    >
      <b-row>
        <!-- Current Password -->
        <b-col cols="12">
          <b-form-group
            label="Current Password"
            label-for="currPass"
          >
            <validation-provider
              #default="{ errors }"
              name="Current Password"
              rules="required"
            >
              <b-form-input
                id="currPass"
                v-model="form.currPass"
                :state="errors.length > 0 ? false:null"
                placeholder="*********"
                type="password"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- New Password -->
        <b-col cols="12">
          <b-form-group
            label="New Password"
            label-for="newPassword"
          >
            <validation-provider
              #default="{ errors }"
              name="New Password"
              rules="required"
            >
              <b-form-input
                id="newPassword"
                v-model="form.newPass"
                type="password"
                :state="errors.length > 0 ? false:null"
                placeholder="*********"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- Confirm New Password -->
        <b-col cols="12">
          <b-form-group
            label="Confirm New Password"
            label-for="cnfrmNewPass"
          >
            <validation-provider
              #default="{ errors }"
              name="New Password"
              rules="required"
            >
              <b-form-input
                id="cnfrmNewPass"
                v-model="form.cnfrmNewPass"
                type="password"
                :state="errors.length > 0 ? false:null"
                placeholder="*********"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex flex-wrap mt-1">
          <b-button
            variant="success"
            type="submit"
            class="mr-1"
          >
            Change Password
          </b-button>
          <b-button
            variant="danger"
            @click="clearForm"
          >
            Clear
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const AuthRepository = RepositoryFactory.get('auth')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      form: {},
      required,
    }
  },
  methods: {
    async changePassword() {
      const data = {
        old_password: this.form.currPass,
        password: this.form.newPass,
        password_confirmation: this.form.cnfrmNewPass,
      }
      try {
        const res = await AuthRepository.changeUserPassword(data)
        if (res.status === 200) {
          this.showSuccessMessage('Password Changed Successfully')
          this.$refs['password-reset-form'].reset()
        } else {
          this.showErrorMessage('Cannot Change Password')
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    clearForm() {
      this.form = {}
    },
  },
}
</script>
