<template>
  <b-card title="General Settings">
    <b-overlay
      :show="loading"
      blur="10px"
      opacity="0.30"
      rounded="sm"
      variant="light"
    >
      <b-form
        @submit.prevent
      >
        <b-row>
          <b-card
            title="Application Zoom"
            class="mb-0 mt-0 w-100"
          ><b-row>
            <b-col
              cols="9"
              md="3"
            >
              <b-form-input
                id="browser_zoom"
                v-model="zoom"
                type="range"
                min="65"
                max="100"
                @input="onZoomChange"
              />
            </b-col>
            <b-col cols="3">
              <div class="text-primary font-weight-bolder">
                {{ `${zoom}%` }}
              </div>
            </b-col>
          </b-row>
          </b-card>
        </b-row>
        <b-row>
          <div class="ml-2">
            This slider controls the zoom level of your application content. Decrease for a more broader view.
          </div>
        </b-row>
      </b-form>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInput, BCard, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BCard,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return {
      loading: false,
      zoom: 100,
    }
  },
  mounted() {
    this.zoom = localStorage.getItem('browser_zoom') && localStorage.getItem('browser_zoom') !== 'null' ? Number(localStorage.getItem('browser_zoom')) : 100
  },
  methods: {
    onZoomChange(e) {
      window.parent.document.body.style.zoom = e / 100
      localStorage.setItem('browser_zoom', e)
    },
  },
}
</script>
