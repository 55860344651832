<template>
  <b-tabs
    ref="businessSettingTabBar"
    :vertical="verticalTabBar.status"
    :nav-wrapper-class="verticalTabBar.wrapper"
  >
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="UsersIcon" />
        <span>Update Profile</span>
      </template>
      <b-card>
        <UpdateAccountForm />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="LockIcon" />
        <span>Change Password</span>
      </template>
      <b-card>
        <ChangePassword />
      </b-card>
    </b-tab>
    <b-tab
      title-item-class="d-flex justify-content-start"
    >
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>General Settings</span>
      </template>
      <b-card>
        <General />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import UpdateAccountForm from '@/components/Setting/Update-Account-Form.vue'
import ChangePassword from '@/components/Setting/Change-password.vue'
import General from '@/components/Setting/General.vue'
import { $themeBreakpoints } from '@themeConfig'

export default {
  components: {
    ChangePassword,
    UpdateAccountForm,
    General,
    BTabs,
    BTab,
    BCard,
  },
  computed: {
    verticalTabBar() {
      if (this.$store.state.app.windowWidth > $themeBreakpoints.lg) {
        return {
          status: true,
          wrapper: 'nav-vertical',
        }
      }
      return {
        status: false,
        wrapper: 'nav-horizontal',
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    this.__preserveTabOnRefresh('businessSettingTabBar', 'businessSettingTabIndex')
  },
}
</script>
