<template>
  <b-form @submit.prevent>

    <b-card
      title="Merchant Settings"
      style="margin-bottom:0px !important;"
    >
      <b-row>

        <!-- first name -->
        <b-col cols="6">
          <b-form-group
            label="Merchant Name"
            label-for="v-merchant-name"
          >
            <b-form-input
              id="v-merchant-name"
              v-model="merchant_name"
            />
          </b-form-group>
        </b-col>
        <!-- first name -->
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-form-group
              label="Invoice Date"
              label-for="invoice-date"
              class="dateFormGroup"
            />
            <b-col class="dateCol d-flex">
              <b-col>
                <b-row>
                  <div class="d-flex mt-1 mb-1">
                    <div
                      class="every"
                    >
                      Every
                    </div>
                    <b-form-input
                      id="period"
                      v-model="period"
                      autocomplete="nope"
                      class="mr-1 inputField"
                      disabled
                    />
                    <b-form-input
                      id="selectedType"
                      v-model="selectedType"
                      autocomplete="nope"
                      class="mr-1 dropdown"
                      disabled
                    />
                  </div>
                </b-row>
                <b-row v-if="selectedType === 'Week'">
                  <div
                    v-for="day in days"
                    :key="day"
                    class="day-column mb-1"
                    :class="{ 'selected': day === selectedDay }"
                  >
                    {{ day }}
                  </div>
                </b-row>
              </b-col>
              <b-col
                cols="5"
                class="mb-1 mt-1 ml-5"
              >
                <b-calendar
                  v-model="value"
                  class="calendar"
                  hide-header
                  readonly
                  today-variant="primary"
                  selected-variant="primary"
                  locale="en"
                  :date-info-fn="dateClass"
                />
              </b-col>
            </b-col>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="3">
          <b-form-group
            label="Invoice Lead Period"
            label-for="invoice-lead-period"
          >
            <b-form-input
              id="invoice-lead-period"
              v-model="invoice_lead_period"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <hr>
    <b-card
      title="Commision Setting"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="Commission"
            label-for="v-email"
          >
            <b-form-input
              id="v-email"
              v-model="commission"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <hr>
    <b-card
      v-if="merchantType"
      title="MERCHANT TYPE"
    >
      <b-badge
        :variant="merchantTypeSelected==='credit'?'light-primary':'light-success'"
        class="font-small-4"
      >
        {{ merchantTypeSelected === 'credit' ? 'Credit' : 'Cash' }}
      </b-badge>
      <hr>
    </b-card>
    <b-card
      title="WAYBILL GENERATION"
    >
      <b-row>
        <b-col cols="12">
          <b-card-text>
            Select this option if you need to generate waybills automatically by the system.
            Keep unselected if you need to add the waybill numbers manually
            <span style="color: red">*</span>
          </b-card-text>
        </b-col>
        <!-- Radio Selection -->
        <b-col cols="6">
          <b-form-radio-group
            v-model="waybillGenSelected"
            :options="waybillGenOptions"
            class="demo-inline-spacing mb-1"
            value-field="value"
            text-field="text"
            disabled-field="disabled"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-overlay
        id="overlay-background"
        :show="loading"
        variant="light"
        opacity="0.30"
        blur="10px"
        rounded="sm"
      >
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :items="rows"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          show-empty
        >
          <template #head(id)>
            <div class="d-flex align-items-start">
              <div>
                <b-form-checkbox
                  id="selectAllCheckbox"
                  v-model="selectAllCheckbox"
                  name="selectAllCheckbox"
                  disabled
                />
              </div>
            </div>
          </template>
          <template #cell(id)="data">
            <div class="d-flex align-items-start">
              <div>
                <div class="font-weight-bolder">
                  <b-form-checkbox
                    v-model="selectedWaybillArray"
                    :value="data.item.id"
                    disabled
                  />
                </div>
              </div>
            </div>
          </template>
        </b-table>
      </b-overlay>
    </b-card>
    <b-card
      v-if="pickupValue"
      title="PickUp Enable"
    >
      <b-row>
        <b-col cols="12">
          <b-card-text>
            Select this option if you need to enable pickup
            Keep unselected if you need to Disable the pickup
            <span style="color: red">*</span>
          </b-card-text>
        </b-col>
        <!-- Radio Selection -->
        <b-col cols="6">
          <b-form-radio-group
            v-model="pickUpEnaSelected"
            :options="pickUpEnaOptions"
            class="demo-inline-spacing mb-1"
            value-field="value"
            text-field="text"
            disabled-field="disabled"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- submit and reset -->
    <b-col cols="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        type="submit"
        class="mr-1 mb-1 custom-button-color"
        @click="setMerchantSettings"
      >
        Submit
      </b-button>
    </b-col>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BCard,
  BCardText,
  BFormRadioGroup,
  BBadge,
  BCalendar,
  BOverlay,
  BTable,
  BFormCheckbox,
  // BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const ResourceRepository = RepositoryFactory.get('businessSettings')
const MerchantRepository = RepositoryFactory.get('merchantSetting')
const waybillSettingsRepository = RepositoryFactory.get('waybillSetting')

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardText,
    BBadge,
    BCalendar,
    BOverlay,
    BTable,
    BFormCheckbox,
    // BPagination,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    const currentDate = new Date()

    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
    const day = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.

    const formattedDate = `${year}-${month}-${day}`
    return {
      merchant_name: '',
      fetchData: [],
      selectedWaybillArray: [],
      rows: [],
      selectAllCheckbox: false,
      is_active: false,
      invoice_lead_period: 0,
      commission: 0,
      merchantType: null,
      merchantTypeSelected: 'cash',
      merchantTypeOptions: [
        { text: 'Cash', value: 'cash', disabled: false },
        { text: 'Credit', value: 'credit', disabled: false },
      ],
      waybillGenSelected: '0',
      waybillGenOptions: [
        { text: 'Auto', value: true, disabled: false },
        { text: 'Manual', value: false, disabled: false },
      ],
      pickUpEnaSelected: '0',
      pickUpEnaOptions: [
        { text: 'Enabled', value: true, disabled: false },
        { text: 'Disabled', value: false, disabled: false },
      ],
      pickupValue: false,
      selectedType: '',
      days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      selectedDay: '',
      period: 0,
      value: '',
      count: 0,
      viewingMonth: currentDate.getMonth(),
      lastWeekCount: 0,
      appliedDate: formattedDate,
      dayInNumber: null,
      loading: false,
      fields: [
        {
          key: 'id',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          key: 'series_name',
          label: 'Series Name',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'prefix',
          label: 'Prefix',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'suffix',
          label: 'Suffix',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
        {
          key: 'preview',
          label: 'Preview',
          thClass: 'text-left',
          tdClass: 'text-left',
          sortable: true,
        },
      ],
    }
  },
  watch: {
    waybillGenSelected() {
      this.selectedWaybillSeries()
    },
  },
  async mounted() {
    await this.readDataforMerchant()
    await this.fetchAssignedWaybillSeries()
    await this.getPickupSetting()
  },
  methods: {
    async readDataforMerchant() {
      try {
        const { data } = (await ResourceRepository.getMerchantSettigs()).data
        this.merchant_name = data.merchant.name
        this.is_active = data.merchant.is_active
        this.period = data.invoice_period
        this.invoice_lead_period = data.invoice_lead_period
        this.commission = data.commission
        this.merchantTypeSelected = data.merchant_type
        this.waybillGenSelected = data.is_waybill_auto
        this.pickUpEnaSelected = data.is_pickup_enable
        this.selectedType = data.invoicing_cycle
        this.selectedDay = data.day_of_week
        const [firstWord] = data.next_invoice_generate_date.split(' ')
        this.appliedDate = firstWord
        if (this.selectedDay === 'Mon') {
          this.dayInNumber = 1
        } else if (this.selectedDay === 'Tue') {
          this.dayInNumber = 2
        } else if (this.selectedDay === 'Wed') {
          this.dayInNumber = 3
        } else if (this.selectedDay === 'Thu') {
          this.dayInNumber = 4
        } else if (this.selectedDay === 'Fri') {
          this.dayInNumber = 5
        } else if (this.selectedDay === 'Sat') {
          this.dayInNumber = 6
        } else if (this.selectedDay === 'Sun') {
          this.dayInNumber = 0
        }
        this.filterTableData()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async fetchAssignedWaybillSeries() {
      this.loading = true
      try {
        const { data } = (await waybillSettingsRepository.fetchAssignedWaybillSeries())
        this.fetchData = data.data
        this.selectedWaybillArray = this.fetchData.map(n => n.waybill_series_id)
        const assignedWaybillSeries = this.fetchData.filter(n => n.waybill_series.is_auto === this.waybillGenSelected)
        this.rows = assignedWaybillSeries.map(n => n.waybill_series)
        if (this.rows.length > 0) {
          this.selectAllCheckbox = true
        }
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.loading = false
    },
    selectedWaybillSeries() {
      const assignedWaybillSeries = this.fetchData.filter(n => n.waybill_series.is_auto === this.waybillGenSelected)
      this.rows = assignedWaybillSeries.map(n => n.waybill_series)
      if (this.rows.length > 0) {
        this.selectAllCheckbox = true
      } else {
        this.selectAllCheckbox = false
      }
    },
    async setMerchantSettings() {
      const setting = {
        name: this.merchant_name,
        is_active: this.is_active,
        business_setting: {
          is_waybill_auto: this.waybillGenSelected,
          is_pickup_enable: this.pickUpEnaSelected,
        },
      }
      try {
        const res = await ResourceRepository.setMerchantSettigs(setting)
        if (res.status === 200) {
          this.showSuccessMessage('Successfully Updated')
          localStorage.removeItem('merchant_business_setting')
        } else {
          this.showErrorMessage('Cannot Update Settings')
        }
      } catch (e) {
        this.showErrorMessage('Something went wrong')
      }
    },
    async getPickupSetting() {
      try {
        const { data } = (await MerchantRepository.getMerchantSettingsResource()).data
        this.pickupValue = data.merchant_pickup_enable
        this.merchantType = data.merchant_type_select
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    dateClass(ymd, date) {
      const day = date.getDay()
      const month = date.getMonth()

      const currentDate = new Date()
      const currentYear = currentDate.getFullYear()
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0') // Months are zero-based, so add 1 and pad with '0' if needed.
      const currentDay = String(currentDate.getDate()).padStart(2, '0') // Pad with '0' if needed.
      const today = `${currentYear}-${currentMonth}-${currentDay}`

      if (this.period !== 0 && this.selectedType === 'Day') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.count
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.count = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.count = this.period
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.count < this.period) {
          this.count += 1
        }

        if (this.count / this.period === 1) {
          this.count = 0
          if (ymd >= today) {
            return 'table-info'
          }
        }
      }

      if (this.period !== 0 && this.dayInNumber !== null && this.selectedType === 'Week') {
        if (this.viewingMonth === 0 && month === 11) {
          this.viewingMonth = 12
        }

        if (this.viewingMonth === 11 && month === 0) {
          this.viewingMonth = -1
        }

        if (this.viewingMonth === month && day === 0) {
          this.lastWeekCount = this.weekCount
        }

        if (day === 0 && this.viewingMonth !== month && month < this.viewingMonth) {
          this.weekCount = this.lastWeekCount
          this.lastWeekCount = 0
        }

        if (month > this.viewingMonth || month < this.viewingMonth) {
          this.viewingMonth = month
        }

        if (ymd === this.appliedDate) {
          this.weekCount = this.period - 1
        }

        if (ymd < this.appliedDate) {
          return ''
        }

        if (this.weekCount < this.period && this.dayInNumber === day) {
          this.weekCount += 1
        }

        if (this.weekCount / this.period === 1 && this.dayInNumber === day) {
          this.weekCount = 0
          return 'table-info'
        }
      }

      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../@core/scss/button-color.scss';
@import "../../../node_modules/@syncfusion/ej2-base/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/fabric.css";
@import "../../../node_modules/@syncfusion/ej2-buttons/styles/fabric.css";
.dateFormGroup{
  height: fit-content;
  margin-bottom: 0;
}
.dateCol{
  border: 1px solid #bbbaba;
  border-radius: 5px;
}
.every{
  margin-top: 8px;
  margin-right: 8px;
}
.inputField{
  width: 50px
}
.dropdown{
  width: 100px;
}
.day-column {
  flex: 1;
  text-align: center;
  font-weight:400;
  border: 2px solid rgba(107, 103, 151, 0.262);
}
.selected {
  background-color: #007bff; /* Change this to your desired selected day color */
  color: white; /* Change this to your desired text color for selected day */
}
.highlighted-date {
  background-color: #007bff;
  color: white;
}

</style>
